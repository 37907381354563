import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { modal__open, modal__close } from './state/zeldamodal'
import ZeldaButton from '../zeldabutton'

const ZeldaModalButton__Connected = ({ modalId, open, close, label, cb, active }) => {

    const callback = () => {
        if (active) {
            return close()
        }

        return open()
    }

    return (
        <ZeldaButton id={`${modalId}-button`} cb={callback} active={active} label={label} />
    )
}

ZeldaModalButton__Connected.propTypes = {
    modalId: PropTypes.string.isRequired,
}


const mapStateToProps = (state, ownProps) => {
    return {
        active: state.modal.isOpen[ownProps.modalId],
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // dispatching plain actions
        open: () => dispatch(modal__open(ownProps.modalId)),
        close: () => dispatch(modal__close(ownProps.modalId)),
    }
}

export const ZeldaModalButton = connect(mapStateToProps, mapDispatchToProps)(ZeldaModalButton__Connected)

