
const QueryParams = {
    confirmed: {
        selector: state => state.queries.email_confirmed,
        action: value => ({ type: 'setQueryEmailConfirmed', payload: value }),
        stringToValue: string => string == "true",
        valueToString: value => value ? "true" : "false",
        defaultValue: false,
    }
}

export default QueryParams;