import { put, takeLatest, take, call, all, fork, select } from 'redux-saga/effects';
import api from '../../api/api'
import * as aa from '../actions/api'

import { ZeldaModal_state as modalState } from 'korok-forest/dist/connected';

import { THANKS_MODAL } from "../../modal_definitions"

const ma = modalState.actions;

export const getCurrentIssue = (state) => state.curr_issue
export const getArticleIDs = (state) => state.editorial.acf.artikkelit
export const getEmailConfirmed = (state) => state.queries.email_confirmed


function* fetchCurrentIssue() {
    try {
        const data = yield call(api.getCurrentIssue)
        yield put(aa.curr_issue__set(data));
    }
    catch (e) {
        yield put(aa.api__error(e));
    }
}

function* fetchEditorial(action) {
    try {
        const data = yield call(api.getEditorial, [action.id])
        yield put(aa.editorial__set(data.editorial, data.article_ids));
    }
    catch (e) {
        yield put(aa.api__error(e));
    }
}

function* fetchArticles(action) {
    try {
        const data = yield call(api.getArticles, [action.ids])
        yield put(aa.articles__set(data));
    }
    catch (e) {
        yield put(aa.api__error(e));
    }
}

function* subscribeEmail(action) {
    try {
        const data = yield call(api.subscribe, action.email, action.name)
        yield put(aa.email__set_response("SUCCESS"));
    }
    catch (e) {
        yield put(aa.email__set_response("FAIL"));
        yield put(aa.api__error(e));
    }
}

function* stopLoading() {
    setTimeout(() => {
        var loadr = document.getElementById("preloader");
        loadr.classList.add("removing");
        setTimeout(() => {
            loadr.classList.add("removed");
            loadr.classList.remove("removing");
        }, 470);
    }, 1000);
}

function* startLoading() {
    var loadr = document.getElementById("preloader");
    loadr.classList.remove("removed");
}

function* showConfirmedModalIfConfirmed() {
    let confirmed = yield select(getEmailConfirmed);
    if (confirmed) {
        yield put(ma.modal__open(THANKS_MODAL));
    }

}

export function* fetchInitialData() {
    yield fork(showConfirmedModalIfConfirmed)
    yield fork(fetchCurrentIssue)
    const action = yield take(aa.CURR_ISSUE__SET)
    yield fork(fetchEditorial, { id: action.curr_issue.id })
    const action2 = yield take(aa.EDITORIAL__SET)
    yield fork(fetchArticles, { ids: action2.article_ids })
    const action3 = yield take(aa.ARTICLES__SET)
    yield put(aa.loading__set(false))
    yield call(stopLoading)
}

export function* fetchChangedEditorial(action) {
    yield put(aa.loading__set(true))
    yield call(startLoading)
    yield fork(fetchEditorial, { id: action.issue_id })
    const action2 = yield take(aa.EDITORIAL__SET)
    yield fork(fetchArticles, { ids: action2.article_ids })
    const action3 = yield take(aa.ARTICLES__SET)
    yield put(aa.loading__set(false))
    yield call(stopLoading)
}


export default function* rootSaga() {
    yield all([
        takeLatest(aa.EDITORIAL__FETCH, fetchEditorial),
        takeLatest(aa.CURR_ISSUE__FETCH, fetchCurrentIssue),
        takeLatest(aa.CURR_ISSUE__CHANGE, fetchChangedEditorial),
        takeLatest(aa.ARTICLES__FETCH, fetchArticles),
        takeLatest(aa.INITIAL__FETCH, fetchInitialData),
        takeLatest(aa.EMAIL__SBSCRB, subscribeEmail)
    ])
}