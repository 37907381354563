import React from "react"
import PropTypes from "prop-types"

const Layout = ({ children, classes }) => {

  return (
    <>
      <main className={classes} id="zelda-content">
        <div id="zelda-page-layout">
          {children}
        </div>
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
