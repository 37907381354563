import React from 'react'

import h from './hestu'

export const ZeldaImage = ({ src, classes }) => {
    const classnames = ["z-image", ...classes].join(" ")
    return (
        <img className={classnames} src={h.fetch(src)}></img>
    )
}

ZeldaImage.defaultProps = {
    classes: []
}

