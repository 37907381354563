import React from 'react'
import { connect } from "react-redux"
import PropTypes from 'prop-types'

const ZeldaHome = (props) => {

    const title = props.title || ""
    const number = props.number || ""

    return (
        <>
            <div id="zelda-title">
                <h1>{`Numero ${number}, `} <b>{` ${title}`} </b> </h1> </div>
            <div id="zelda-logo" z-only="dsk">
                <img src="./assets/logo_z.png"></img>
            </div>
            <div id="zelda-logo" z-only="mb">
                <img src="./assets/zelda1.png"></img>
            </div>
        </>
    )

}


ZeldaHome.propTypes = {
    logo_url: PropTypes.string,
    edition_title: PropTypes.string,
}

const mapStateToProps = (state) => {
    return {
        title: state.data.editorial.title,
        number: state.data.editorial.number,
    }
}


export default connect(mapStateToProps)(ZeldaHome)


