
class Hestu {

    static prfx = "./resources/"

    constructor() {
        if (typeof window.ZeldaAssets != 'undefined') {
            this.__assets = window.ZeldaAssets.assets
            this.__props = window.ZeldaAssets.props
        }
        else {
            this.__assets = {}
            this.__props = {}
        }
    }


    fetch(item) {

        if (item in this.__assets) {
            return this.__assets[item]
        }

        return `${Hestu.prfx}${item}`

    }

    read(prop) {

        if (prop in this.__props) {
            return this.__props[prop]
        }

        return null

    }


}

const instance = new Hestu();
Object.freeze(instance);

export default instance




