function makeActionCreator(type, ...argNames) {
    return function (...args) {
        const action = { type }
        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index]
        })
        return action
    }
}


/*
 * action types
 */
export const CURR_ISSUE__FETCH = 'CURR_ISSUE/FETCH'
export const CURR_ISSUE__SET = 'CURR_ISSUE/SET'
export const CURR_ISSUE__CHANGE = 'CURR_ISSUE/CHANGE'

export const EDITORIAL__FETCH = 'EDITORIAL/FETCH'
export const EDITORIAL__SET = 'EDITORIAL/SET'

export const ARTICLES__FETCH = 'ARTICLES/FETCH'
export const ARTICLES__SET = 'ARTICLES/SET'

export const API__FAIL = 'API/FAIL'

export const LOADING__SET = 'LOADING/SET'

export const INITIAL__FETCH = 'INITIAL/FETCH'

export const EMAIL__SBSCRB = 'EMAIL/SBSCRB'
export const EMAIL__SET_RESPONSE = 'EMAIL/SET_RESPONSE'


/*
 * action creators
 */
export const curr_issue__fetch = makeActionCreator(CURR_ISSUE__FETCH);
export const curr_issue__set = makeActionCreator(CURR_ISSUE__SET, 'curr_issue');
export const curr_issue__change = makeActionCreator(CURR_ISSUE__CHANGE, 'issue_id');

export const editorial__fetch = makeActionCreator(EDITORIAL__FETCH, 'id');
export const editorial__set = makeActionCreator(EDITORIAL__SET, 'editorial', 'article_ids');

export const articles__fetch = makeActionCreator(ARTICLES__FETCH, 'ids');
export const articles__set = makeActionCreator(ARTICLES__SET, 'articles');

export const email__sbscrb = makeActionCreator(EMAIL__SBSCRB, 'email', 'name');
export const email__set_response = makeActionCreator(EMAIL__SET_RESPONSE, 'response');

export const api__error = makeActionCreator(API__FAIL, 'api_error')

export const initial__fetch = makeActionCreator(INITIAL__FETCH)

export const loading__set = makeActionCreator(LOADING__SET, 'state')