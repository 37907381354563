import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './scss/zcontent.scss'

import h from './hestu'
import ZeldaCredits from './zeldacredits'

export default class ZeldaContent extends Component {
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.any,
        classes: PropTypes.array.isRequired,
        credits: PropTypes.array.isRequired,
        injectTitleStyle: PropTypes.array
    }

    static defaultProps = {
        credits: [],
        classes: [],
        noCover: false,
        injectTitleStyle: []
    }

    componentDidMount() {

    }

    render() {
        const {
            children,
            title,
            subtitle,
            classes,
            credits,
            noCover,
            injectTitleStyle
        } = this.props

        var sbttl
        if (subtitle instanceof Array) {
            sbttl = subtitle
        }
        else {
            sbttl = [subtitle];
        }

        return (
            <div id={"zelda-content"} className={[styles["z-article-content"], "zelda-article"].join(' ')}>

                <div z-only="dsk" className={styles["home_button"]}>
                    <a href={h.read('home-url')}>
                        <img src={h.fetch('z-logo.png')} />
                    </a>
                </div>

                <div z-only="mb" className={styles["home_button-mb"]}>
                    <a href={h.read('home-url')}>
                        <img src={h.fetch('zelda1.png')} />
                    </a>
                </div>

                <div className={styles["z-article"]}>
                    <div className={[styles["title_card"], "zelda-title-image"].join(" ")}>
                        {!noCover && <img
                            src={h.fetch('cover-image.jpg')} />
                        }
                    </div>
                    {/* 
                This is a pure css + html solution for the "sticky" title effect, which is responsive to the varying aspect ration of the image it is overlayed with.

                It's not in use now, due not being able to figure out the text contrast issue. But it is preserved for posteriority    


                <div className={styles["zelda-article-title-container-container"]}>
                    <div className={[styles["zelda-article-title-fixed-spacer"], "zelda-title-image"].join(" ")}>
                        <img
                            src={h.fetch('cover-image.jpg')} />
                    </div>
                    <div className={styles["zelda-article-title-container"]}>
                        <div className={styles["zelda-article-title"]}>
                            <span>
                                <h1>{title}</h1>
                                <h2>{subtitle}</h2>
                            </span>
                        </div>
                        <div className={[styles["zelda-article-title-fixed-spacer"], "zelda-title-image"].join(" ")}>
                            <img
                                src={h.fetch('cover-image.jpg')} />
                        </div>
                    </div>
                </div> */}
                    {/* <div className={styles["zelda-article-title-stuck-spacer"]} /> */}

                    <div className={[styles["zelda-article-title"], "article-title"].join(" ")}
                        style={injectTitleStyle}>
                        <span>
                            <h1>{title}</h1>
                            {sbttl.map((item) => (<h2>{item}</h2>))}
                        </span>
                    </div>
                    <ZeldaCredits credits={credits} />
                    <div className={[styles["article-content"], "article-content", ...classes].join(" ")}>
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}
