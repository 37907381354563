import React from 'react';
import { connect } from 'react-redux'

import { DD_MODAL, ABOUT_MODAL, CONTACT_MODAL, EMAIL_MODAL } from '../../modal_definitions'

import { ZeldaModalContent, ZeldaMenu, ZeldaMenuItem } from 'korok-forest'

import { ZeldaModal_state as ms, ZeldaModal } from 'korok-forest/dist/connected'

import { curr_issue__change } from '../../state/actions/api'


const DDNav = ({ open_about, close_self, open_contact, open_subscribe, change_issue, issues }) => {

    return (
        <ZeldaModal modalId={DD_MODAL} flex closable>
            <ZeldaModalContent>
                <ZeldaMenu close={close_self}>
                    <ZeldaMenuItem label="MIKÄ ON ZELDA?" click={open_about} />
                    <ZeldaMenuItem label="NUMEROT">
                        <ZeldaMenuItem label="1 Ruumis" click={() => { window.location = "https://zelda.fi/ruumis" }} />
                        <ZeldaMenuItem label="2 Rakkaus" click={() => { window.location = "https://zelda.fi/rakkaus" }} />
                        <ZeldaMenuItem label="3 Valta" click={() => { window.location = "https://zelda.fi/valta" }} />
                        <ZeldaMenuItem label="4 Mieli" click={() => { window.location = "https://zelda.fi/mieli" }} />
                        <ZeldaMenuItem label="5 Kulissit" click={() => { window.location = "https://zelda.fi/kulissit" }} />
                        {issues && issues.map((item, key) => (
                            <ZeldaMenuItem key={key} label={`${item.post_title}`} click={() => {
                                change_issue(item.ID);
                            }}
                            />
                        ))}
                    </ZeldaMenuItem>
                    <ZeldaMenuItem label="CONTACT" click={open_contact} />
                    <ZeldaMenuItem label="SUBSCRIBE" click={open_subscribe} />
                </ZeldaMenu>
            </ZeldaModalContent>
        </ZeldaModal>
    )
}

const mapDispatchToProps = (dispatch) => (
    {
        open_about: () => { dispatch(ms.actions.modal__open(ABOUT_MODAL)) },
        open_contact: () => { dispatch(ms.actions.modal__open(CONTACT_MODAL)) },
        open_subscribe: () => { dispatch(ms.actions.modal__open(EMAIL_MODAL)) },
        close_self: () => { dispatch(ms.actions.modal__close(DD_MODAL)) },
        change_issue: (id) => { dispatch(curr_issue__change(id)) },
    }
)

const mapStateToProps = (state) => {
    return { issues: state.data.curr_issue.issues }
}

export const DDNavMenu = connect(mapStateToProps, mapDispatchToProps)(DDNav)
