import React from "react"
import PropTypes from "prop-types"

import { ZeldaModalButton } from 'korok-forest/dist/connected'

import { NumberSelector } from './numberselector'
import { DDHamburgerMenu } from './mobile/ddhamburgermenu'

import { ABOUT_MODAL, CONTACT_MODAL, EMAIL_MODAL } from '../modal_definitions'



const NavBar = ({ }) => {


    return (
        <div id="zelda-navbar">
            <div z-only="dsk">
                <ZeldaModalButton
                    label="Mikä on zelda?"
                    modalId={ABOUT_MODAL}
                />
                <NumberSelector />
                <ZeldaModalButton
                    label="contact"
                    modalId={CONTACT_MODAL}
                />
                <ZeldaModalButton
                    label="SUBSCRIBE"
                    modalId={EMAIL_MODAL}
                />
            </div>
            <div z-only="mb">
                <DDHamburgerMenu />
            </div>
        </div>
    )


}

export default NavBar;