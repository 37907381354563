import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

import state from './state/zeldamodal'

import { ZeldaModal as ZM } from '../zeldamodal'


const ZeldaModal__Connected = ({ modalId, isOpen, close, children, register, closable, ...props }) => {


    const closeThis = () => {
        close(modalId)
    }

    useEffect(() => {
        register(modalId);
    }, [])

    return (
        <ZM modalId={modalId} close={closeThis} isOpen={isOpen} closable={closable} {...props}>
            {children}
        </ZM>
    )
}

ZeldaModal__Connected.propTypes = {
    modalId: PropTypes.string.isRequired,
    closable: PropTypes.bool,
    isOpen: PropTypes.bool
}


const mapStateToProps = (state, ownProps) => {
    const { modalId } = ownProps

    return {
        isOpen: state.modal.isOpen[modalId],
    }
}

const mapDispatchToProps = dispatch => {
    return {
        close: (id) => dispatch(state.actions.modal__close(id)),
        register: (id) => dispatch(state.actions.modal__register(id))
    }
}

export const ZeldaModal = connect(mapStateToProps, mapDispatchToProps)(ZeldaModal__Connected)

export const ZeldaModal_state = state;
