import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './scss/zcredits.scss'

import { ZeldaModal, ZeldaModalContent } from './zeldamodal'

export default class ZeldaCredits extends Component {

    static propTypes = {
        credits: PropTypes.array.isRequired,
        default: PropTypes.number
    }

    static defaultProps = {
        default: 0,
        credits: []
    }

    constructor(props) {
        super(props)
        this.state = { open: props.default }
    }

    toggle(ind) {
        let i = ind + 1
        this.setState({ open: (this.state.open != i) * i })
    }


    close() {
        this.setState({ open: 0 })
    }


    render() {

        const { open } = this.state

        const { credits } = this.props


        const buttons = credits.map((item, indx) => {
            if (item.content == null) {
                return (
                    <button
                        className={styles["credits-button"]}
                        onClick={() => { }}>
                        <span><strong>{item.credit + ":"} </strong> {item.name}</span></button>)
            }
            return (
                <button
                    className={styles["credits-button"]}
                    onClick={() => { this.toggle(indx) }}>
                    <span><strong>{item.credit + ":"} </strong> {item.name}</span></button>)
        }
        );

        return (
            <div className={styles["credits-container"]}>
                {buttons}
                <ZeldaModal
                    modalId="z-credits-modal"
                    isOpen={open > 0}
                    closable
                    flex
                    close={() => { this.close() }}
                >
                    <ZeldaModalContent compact>
                        {open > 0 &&
                            credits[open - 1].content
                        }
                    </ZeldaModalContent>
                </ZeldaModal>
            </div>
        )
    }

}