import React from 'react'

import IndexPage from './pages/index-page'


export default () => {

    return (
        <IndexPage />
    )
}