import React, { useState } from 'react';
import { connect } from 'react-redux';

import { ZeldaButton, ZeldaModalContent } from 'korok-forest'

import { email__sbscrb } from '../state/actions/api'


const EmailForm_c = ({ email_response, subscribe }) => {

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')

    const handleSubmit = (event) => {
        subscribe(email, name);
        event.preventDefault();
    }

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const onNameChange = (event) => {
        setName(event.target.value);
    }

    return (
        <ZeldaModalContent>
            <div id="emailform">
                <p>Zelda julkaisee uutiskirjettä!</p>
                <p> Uutiskirjeessä käsittelemme jotain tiettyä aihepiiriä meitä inspiroivien tekstien, taiteen ja artikkeleiden kautta. Esittelemme uutiskirjeissämme myös vanhojen numeroidemme ajankohtaista sisältöä sekä sivustolla julkaistuja uusia juttuja. Uutiskirjeet sisältävät myös vinkkejä kaikilta kulttuurin alueilta sarjoista, podcasteihin, kirjoihin ja artikkeleihin sekä kokemuksiin ja tapahtumiin ruudun ulkopuolella.  </p>
                <p>
                    Liity postituslistallemme, niin saat uutiskirjeen suoraan omaan sähköpostiisi!
                </p>


                <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", width: "300px", "maxWidth": "100%", alignContent: "center", margin: "3em auto" }}>
                    {email_response == "SUCCESS" && <h5>
                        Tilaus onnistui! Tarkista vielä, että sait vahvistusviestin sähköpostiisi </h5>}

                    {email_response == "FAIL" && <h5>
                        Voi ei! Jokin meni pieleen. Tarkistatko vielä, että sähköpostiosoittesi on kirjoitettu oikein.</h5>}
                    <label style={{ margin: "0.5em 0" }}>
                        Email:    </label><input type="text" value={email} onChange={onEmailChange} />
                    <label style={{ margin: "0.5em 0" }}>
                        Screen name:</label> <input type="text" value={name} onChange={onNameChange} />
                    <button className="z-button" type="submit"> Tilaa </button>
                </form>

            </div>
        </ZeldaModalContent>
    )
}

const mapStateToProps = (state) => {
    return (
        {
            email_response: state.data.email_response,
        }
    )
}

const mapDispatchToProps = (dispatch) => {
    return ({
        subscribe: (email, name) => { dispatch(email__sbscrb(email, name)) }
    })
}

export const EmailForm = connect(mapStateToProps, mapDispatchToProps)(EmailForm_c)

