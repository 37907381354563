import React, { useState } from 'react'
import { Carousel } from 'react-responsive-carousel';

import style from './scss/zgallery.scss'

require('react-dom');
window.React2 = require('react');
console.log(window.React1 === window.React2);

const ArrowButton = ({ right, gray }) => {

    const color = gray ? "#969798" : "#000000"

    var transfrm = { transform: "scale(1, 1) translate(0, 0)" };

    if (right) {
        transfrm = { transform: "scale(-1, 1) translate(0, 0)" };
    }

    return (
        <svg version="1.1" viewBox="0 0 180 220" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ width: "40px", ...transfrm }}>
            <defs>
                <path id="zarrow" d="m106.54 189.26c-6.58 0-12.85-2.8-17.24-7.68-12.5-13.9-44.46-49.45-57.3-63.73-2.96-3.28-2.96-8.26-0.02-11.55 12.85-14.36 45.35-50.69 57.98-64.81 3.98-4.44 9.65-6.97 15.61-6.97h24.37c7 0 10.63 8.34 5.85 13.46-7.98 8.55-27.93 29.91-59.84 64.1 31.49 33.56 51.17 54.54 59.04 62.93 5.07 5.4 1.24 14.25-6.17 14.25h-22.28z" />
            </defs>
            <use fill={color} stroke={color} xlinkHref="#zarrow" />
        </svg>
    )
}


export const ZeldaGallery = ({ children, ...props }) => {

    const [current, setCurrent] = useState(0);

    const len = children.length - 1

    const prev = () => {
        setCurrent(Math.max((current - 1), 0))
    }
    const next = () => {
        setCurrent(Math.min((current + 1), len))
    }

    const updateCurrentSlide = (index) => {
        if (current != index) {
            setCurrent(index)
        }
    }

    return (
        <div className={style["z-gallery"]}>
            <button className={style["z-gallery-btn-right"]} onClick={prev}>
                <ArrowButton gray={current == 0} />
            </button>
            <div className={style["z-gallery-slider"]}>
                <Carousel selectedItem={current} showThumbs={false} showArrows={false} showStatus={false} showIndicators={false} onChange={updateCurrentSlide} {...props}>
                    {children}
                </Carousel>
            </div>
            <button className={style["z-gallery-btn-left"]} onClick={next}>
                <ArrowButton right gray={current == len} />
            </button>
        </div>
    )


}