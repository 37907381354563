import * as aa from "../actions/api"

const reducer = (state = { editorial: {}, curr_issue: {}, article_ids: [], articles: [], email_response: null, loading: true }, action) => {
    switch (action.type) {
        case aa.CURR_ISSUE__FETCH:
        case aa.CURR_ISSUE__SET:
            return { ...state, curr_issue: action.curr_issue };
        case aa.CURR_ISSUE__CHANGE:
            return { ...state, curr_issue: { ...state.curr_issue, id: action.issue_id } };
        case aa.EDITORIAL__FETCH:
        case aa.EDITORIAL__SET:
            return { ...state, editorial: action.editorial, article_ids: action.article_ids };
        case aa.ARTICLES__FETCH:
        case aa.ARTICLES__SET:
            return { ...state, articles: action.articles };
        case aa.EMAIL__SET_RESPONSE:
            return { ...state, email_response: action.response };
        case aa.API__FAIL:
            return { ...state, api_error: action.api_error };
        case aa.LOADING__SET:
            return { ...state, loading: action.state };
        default:
            return state;
    }
};

export default reducer;