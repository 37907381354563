import React, { useState } from 'react';
import { connect } from 'react-redux';

import { ZeldaButton, ZeldaModalContent } from 'korok-forest'


const WhatIsIt_c = ({ about_content_en, about_content_fi }) => {

    const [lang, setLang] = useState('fi')

    let label
    let langtoset
    let content

    if (lang == "en") {
        label = "Suomeksi"
        langtoset = "fi"
        content = { __html: about_content_en }
    }

    else if (lang == "fi") {
        label = "English"
        langtoset = "en"
        content = { __html: about_content_fi }
    }

    const button = <ZeldaButton label={label} cb={() => { setLang(langtoset) }} active={false} id={"z-about-lang-button"} />

    return (
        <>
            <div>
                {button}
            </div>
            <ZeldaModalContent>
                <div dangerouslySetInnerHTML={content}>

                </div>
            </ZeldaModalContent>
        </>
    )
}

const mapStateToProps = (state) => {
    return (
        {
            about_content_en: state.data.curr_issue.about_en,
            about_content_fi: state.data.curr_issue.about_fi,
        }
    )
}

export const WhatIsIt = connect(mapStateToProps)(WhatIsIt_c)

