import { createStore as reduxCreateStore, applyMiddleware, compose } from 'redux';
import rootReducer from '.';

import queryParams from './queryparams'


import ReduxQuerySync from 'redux-query-sync'

import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga';
import { fetchInitialData } from './saga'


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const storeEnhancer = ReduxQuerySync.enhancer({
    params: queryParams,
    initialTruth: "location",
    replaceState: true,
})

const createStore = () => reduxCreateStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware), storeEnhancer)
);


export default () => {
    const store = createStore();
    sagaMiddleware.run(rootSaga);
    sagaMiddleware.run(fetchInitialData);

    return store;
};