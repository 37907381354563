import React from "react"
import { useRef, useLayoutEffect, useEffect, useState } from 'react'
import PropTypes from "prop-types"
import { connect } from "react-redux"

import TitleCard from './titlecard'

import { ZeldaModal_state as ms } from 'korok-forest/dist/connected'

const EditorialCard = ({ editorial, click }) => {
    return (
        <div onClick={click} className={"zelda-editorial-card"}>
            <div>

                {editorial.acf &&
                    <img src={editorial.acf.illustration.url}></img>}
                <h2>Pääkirjoitus</h2>
            </div>
        </div>
    )
}


const Feed = ({ articles, editorial, openEditorial, article_ids, loading }) => {


    const containmentRef = useRef(null);
    const feedRef = useRef(null);
    const overlayRef = useRef(null);

    const [hasOverflow, setHasOverflow] = useState(true);

    function updateScroll(e) {
        overlayRef.current.scrollTo(0, 0.6 * e.target.scrollTop)
    }

    if (articles.length == 0) {
        return (
            <div id={"zelda-feed"}>
                <h1>Loading...</h1>
            </div>)
    }

    if (loading) {
        return (<>loading</>)
    }

    const articlemap = articles.reduce((map, item) => { map[item.id] = item; return map }, {})

    const sorted_articles = article_ids.map((item) => (articlemap[item]))

    const objectsToRender = sorted_articles.map((article) =>
        <TitleCard key={article.slug} article={article} containment={containmentRef} />
    );

    objectsToRender.unshift(<EditorialCard editorial={editorial} click={openEditorial} />)

    objectsToRender.push(
        <div className="feed-spacer"></div>
    )


    var overlayImages = [];
    if (editorial.acf.floaties) {
        overlayImages = editorial.acf.floaties.map(
            (item) => (<img className="floatie" src={item} alt="" />)
        )
    }

    return (
        <>
            <div id={"zelda-overlay"} ref={overlayRef}>
                <div id={"overlay-container"}>
                    {overlayImages}
                </div>
            </div>
            <div id={"zelda-feed"}>
                <div id="feed-view-port" ref={containmentRef} />
                <div id="zelda-feed-content" ref={feedRef} onScroll={updateScroll}>
                    {objectsToRender}
                </div>
            </div>
        </>
    )


}

Feed.propTypes = {
    articles: PropTypes.array,
}


const mapStateToProps = (state) => {
    return { articles: state.data.articles, editorial: state.data.editorial, article_ids: state.data.article_ids, loading: state.data.loading }
}


const mapDispatchToProps = (dispatch) => {
    return { openEditorial: () => { dispatch(ms.actions.modal__open("z-editorial-modal")) } }
}


export default connect(mapStateToProps, mapDispatchToProps)(Feed)



