import { combineReducers } from 'redux';
import data from './reducers/data'
import queries from './reducers/queries'

import { ZeldaModal_state as modal } from 'korok-forest/dist/connected'

export default combineReducers(
    {
        data,
        queries: queries,
        [modal.r_key]: modal.reducer
    });