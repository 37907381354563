import React, { useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from 'prop-types';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Feed from "../components/feed"
import ZeldaHome from '../components/zeldahome'
import NavBar from "../components/navbar";
import ModalZone from "../components/modalzone";

import { ABOUT_MODAL, NUMBERS_MODAL, CONTACT_MODAL, EDITORIAL_MODAL, EMAIL_MODAL } from '../modal_definitions'

import { ZeldaModal_state as ms } from 'korok-forest/dist/connected'

import styles from '../sass/main.scss'

const IndexPage = ({ edition_title, dispatch }) => {

  useEffect(
    () => {
      dispatch(ms.actions.modal__group([ABOUT_MODAL, NUMBERS_MODAL, CONTACT_MODAL, EMAIL_MODAL]));
      dispatch(ms.actions.modal__group([ABOUT_MODAL, EDITORIAL_MODAL, CONTACT_MODAL, EMAIL_MODAL]))
    }
    , [])


  const classes = edition_title ? edition_title.toLowerCase() : "";

  return (
    <Layout id="zelda-content" classes={classes}>
      <SEO title={`Zelda · ${edition_title}`} />
      <ZeldaHome />
      <NavBar />
      <Feed />
      <ModalZone />
    </Layout>
  )
}

Feed.propTypes = {
  edition_title: PropTypes.string,
}


const mapStateToProps = (state) => {
  return { edition_title: state.data.editorial.title }
}

export default connect(mapStateToProps)(IndexPage)
