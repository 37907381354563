import React from 'react'
import { ZeldaModal } from 'korok-forest/dist/connected'
import { ZeldaModalContent } from 'korok-forest'

import { WhatIsIt } from './whatisit'

import { EmailForm } from './email_form'

import { connect } from 'react-redux'

import { ABOUT_MODAL, CONTACT_MODAL, EDITORIAL_MODAL, EMAIL_MODAL, THANKS_MODAL } from '../modal_definitions'


const ModalZone_C = ({ editorial }) => {




    return (
        <div id="zelda-modal-zone">
            <ZeldaModal modalId={ABOUT_MODAL} closable>
                <WhatIsIt />
            </ZeldaModal>
            <ZeldaModal modalId={CONTACT_MODAL} closable>
                <ZeldaModalContent>
                    {editorial.acf &&
                        <div dangerouslySetInnerHTML={{ __html: editorial.acf.contact }}></div>
                    }
                </ZeldaModalContent>
            </ZeldaModal>
            <ZeldaModal modalId={EMAIL_MODAL} closable>
                <EmailForm />
            </ZeldaModal>
            <ZeldaModal modalId={EDITORIAL_MODAL} closable>
                <ZeldaModalContent>
                    {editorial.acf &&
                        <div dangerouslySetInnerHTML={{ __html: editorial.acf.content }}></div>
                    }
                </ZeldaModalContent>
            </ZeldaModal>
            <ZeldaModal modalId={THANKS_MODAL} closable>
                <p> Sähköpostin vahvistus onnistui! </p>
            </ZeldaModal>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        editorial: state.data.editorial
    }
}

const ModalZone = connect(mapStateToProps)(ModalZone_C)

export default ModalZone



