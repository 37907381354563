import axios from 'axios'
import qs from 'qs'

const api_base = process.env.REACT_APP_API_BASE
const ck_api_base = process.env.REACT_APP_CK_API_BASE
const ck_api_key = process.env.REACT_APP_CK_API_KEY
const ck_form_key = process.env.REACT_APP_CK_FORM_KEY

axios.defaults.paramsSerializer = params => {
  return qs.stringify(params, { arrayFormat: 'repeat' });
};

const _getCurrentIssue = () => {
  return axios.get(`${api_base}/acf/v3/options/julkaisu-asetukset`).then(result => {
    return ({
      id: result.data.acf.current_issue.ID,
      issues: result.data.acf.julkaistut_numerot,
      about_fi: result.data.acf.about_fi,
      about_en: result.data.acf.about_en
    })
  })
}

const _getEditorial = (id) => {
  return axios.get(`${api_base}/ocarina/v1/editorial/${id}`).then(result => {
    return ({
      editorial: {
        ...result.data,
        title: result.data.acf.full_title,
        number: result.data.acf.number
      },
      article_ids: result.data.acf.artikkelit.map((x) => x.artikkeli)
    })
  })
}

const _getArticles = (ids) => {
  return axios.get(`${api_base}/ocarina/v1/articles`, { params: { "include[]": ids, "per_page": 25 } }).then(result => {
    return (result.data)
  })
}

const _subscribe = (email, name) => {
  return axios.post(`${ck_api_base}/v3/forms/${ck_form_key}/subscribe`, { email: email, api_key: `${ck_api_key}`, name: name }).then(result => {
    return (result.data)
  })
}

const OcarinaApi = {
  getCurrentIssue: _getCurrentIssue,
  getEditorial: _getEditorial,
  getArticles: _getArticles,
  subscribe: _subscribe
}

export default OcarinaApi