import React from 'react'
import { connect } from 'react-redux'

import { ZeldaModal_state as ms } from 'korok-forest/dist/connected'
import { DD_MODAL } from '../../modal_definitions'
import { DDNavMenu } from './ddnav'


const DDHamburger = ({ open_nav }) => (
    <div>
        <button onClick={open_nav}>
            <svg viewBox="0 0 100 80" width="35" height="40">
                <rect width="100" height="15" rx="8"></rect>
                <rect y="30" width="100" height="15" rx="8"></rect>
                <rect y="60" width="100" height="15" rx="8"></rect>
            </svg>
        </button>
        <DDNavMenu />
    </div>
)

const mapDispatchToProps = (dispatch) => (
    {
        open_nav: () => { dispatch(ms.actions.modal__open(DD_MODAL)) }
    }
)

export const DDHamburgerMenu = connect(null, mapDispatchToProps)(DDHamburger)