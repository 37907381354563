import React from "react"
import style from '../sass/main.scss'
import { useState } from 'react'

import VisibilitySensor from 'react-visibility-sensor'


const TitleCard = ({ article, containment }) => {

    const [active, setActive] = useState(false)

    var cardClasses = "zelda-title-card"
    if (active) {
        cardClasses += " active"
    }

    return (
        <VisibilitySensor partialVisibility={true} containment={containment.current} scrollCheck={true} onChange={(isVisible) => {
            setActive(isVisible)
        }
        }>
            <div className={cardClasses} onClick={() => { window.location = article.link }}>
                <div>
                    <img src={article.acf.illustration.url}></img>
                    {
                        article.acf.verho &&
                        <img className={"zelda-mask "} src={article.acf.verho.url}></img>}
                    <h2>{article.title.rendered}</h2>
                </div>
            </div>
        </VisibilitySensor>
    )


}

export default TitleCard;