import React from 'react'

import style from './scss/glitch.scss'

import h from './hestu'

export const ZeldaGlitchImage = ({ src, classes }) => {
    const classnames = ["z-image", ...classes].join(" ")
    return (
        <div className={style["glitch_image"]}>
            <img className={classnames} src={h.fetch(src)}></img>
            <img className={classnames} src={h.fetch(src)}></img>
            <img className={classnames} src={h.fetch(src)}></img>
        </div>
    )
}

ZeldaGlitchImage.defaultProps = {
    classes: []
}


