import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './scss/zbutton.scss'

export class ZeldaButton extends Component {

    static propTypes = {
        cb: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        id: PropTypes.string,
    }

    static defaultProps = {
        cb: () => { },
        label: "Button",
        active: false
    }

    constructor(props) {
        super(props)
    }


    render() {
        const { cb, label, active, id } = this.props

        let classes = [styles["z-button"]]

        if (active) {
            classes.push(styles["active"])
        }

        return (
            <button id={id} className={classes.join(' ')} onClick={cb}>
                <h3>{label}</h3>
            </button>)
    }

}

export default ZeldaButton;