import React from 'react'
import { useState } from 'react'

import PropTypes from 'prop-types'

import style from './scss/zeldamenu.scss'


const endow = (children, c, level) => {
    return React.Children.map(children, (child) => (
        React.cloneElement(child, { c: c, level: level })
    ))
}

const ZeldaMenu = ({ close, children }) => {

    const c = (click) => {

        return () => {
            click()
            close()
        }

    }

    const level = 1;

    const endowed_children = endow(children, c, level)

    return (
        <ul className={[style["zelda-menu"], style[`zelda-menu-${level}`]].join(' ')}>
            {endowed_children}
        </ul>
    )
}

const ZeldaMenuItem = ({ label, click, c, children, level }) => {


    if (!children || (children && !children.length)) {
        return (
            <li
                className={[style["zelda-menu-item"], style[`zelda-menu-item-${level}`]].join(' ')}>
                <button onClick={c(click)} >
                    <h4>{label}</h4>
                </button>
            </li>
        )
    }

    const [open, setOpen] = useState(false);
    const sublevel = level + 1;

    const endowed_children = endow(children, c, level)

    return (
        <li className={[style["zelda-menu-item"], style[`zelda-menu-item-${level}`]].join(' ')}>
            <button onClick={c(click)} onClick={() => { setOpen(!open) }}>
                <h4>{label}</h4>
            </button>
            {open && <ul
                className={[style["zelda-menu"], style[`zelda-menu-${sublevel}`]].join(' ')}>
                {endowed_children}
            </ul>}
        </li>);
}

ZeldaMenuItem.propTypes = {
    click: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
}


ZeldaMenuItem.defaultProps = {
    click: () => { console.log("Clicked") }
}

ZeldaMenu.propTypes = {
    close: PropTypes.func.isRequired
}

export { ZeldaMenuItem, ZeldaMenu }