import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ruksi from './assets/ruksi.png'

import styles from './scss/zmodal.scss'


export const ZeldaModalContent = ({ children }) => (
    <div className={[styles["z-modal-content"], "zelda-modal-content"].join(' ')}>
        {children}
    </div>
)

export class ZeldaModal extends Component {

    static propTypes = {
        isOpen: PropTypes.bool,
        close: PropTypes.func,
        modalId: PropTypes.string,
        closable: PropTypes.bool,
        flex: PropTypes.bool,
    }

    static defaultProps = {
        isOpen: false
    }

    constructor(props) {
        super(props)
    }


    render() {
        const { isOpen, children, close, modalId, closable, flex } = this.props

        return (
            <span>
                {isOpen &&
                    <div id={modalId} className={[styles["z-modal-container"], flex ? styles["z-modal-container-flex"] : "", "zelda-modal-container"].join(' ')}>
                        {closable &&
                            <img className={styles["z-modal-close"]} onClick={close} src={ruksi}></img>
                        }
                        {children}
                    </div>
                }
            </span>)
    }

}