import React from 'react'
import { connect } from 'react-redux'

import { ZeldaModalButton, ZeldaModal } from 'korok-forest/dist/connected'
import { ZeldaMenu, ZeldaMenuItem } from 'korok-forest'
import { ZeldaModalContent } from 'korok-forest'


import { ZeldaModal_state as ms } from 'korok-forest/dist/connected'

import { curr_issue__change } from '../state/actions/api'


const NumberSelector_C = ({ close_self, issues, change_issue }) => {

    return (
        <div style={{ position: "relative", "zIndex": "9001" }
        }>
            <ZeldaModalButton
                label="Numerot"
                modalId="z-numbers-modal" />
            <ZeldaModal modalId="z-numbers-modal" flex>
                <ZeldaModalContent>
                    <ZeldaMenu close={close_self}>
                        <ZeldaMenuItem label="1 Ruumis" click={() => { window.location = "https://zelda.fi/ruumis" }} />
                        <ZeldaMenuItem label="2 Rakkaus" click={() => { window.location = "https://zelda.fi/rakkaus" }} />
                        <ZeldaMenuItem label="3 Valta" click={() => { window.location = "https://zelda.fi/valta" }} />
                        <ZeldaMenuItem label="4 Mieli" click={() => { window.location = "https://zelda.fi/mieli" }} />
                        <ZeldaMenuItem label="5 Kulissit" click={() => { window.location = "https://zelda.fi/kulissit" }} />
                        {issues && issues.map((item, key) => (
                            <ZeldaMenuItem key={key} label={`${item.post_title}`} click={() => {
                                change_issue(item.ID);
                            }}
                            />
                        ))}
                    </ZeldaMenu>
                </ZeldaModalContent>
            </ZeldaModal>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => (
    {
        close_self: () => { dispatch(ms.actions.modal__close("z-numbers-modal")) },
        change_issue: (id) => { dispatch(curr_issue__change(id)) },
    }
)

const mapStateToProps = (state) => {
    return { issues: state.data.curr_issue.issues }
}


export const NumberSelector = connect(mapStateToProps, mapDispatchToProps)(NumberSelector_C)